import React, { useCallback, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { IAppointmentDetails } from 'shared/interfaces/IAppointmentDetails';
import {
  useCreateDirectBookingAppointmentMutation,
  useCreateClinicWidgetRequestMutation,
  WidgetRequestType,
  ClinicPimsIntegrationSelectionFragment,
  useGetHasConflictingAppointmentQuery,
} from 'shared/types/graphql';
import { getFormattedDate, getFormattedTime, getIndefiniteArticle } from 'shared/utils';
import { useForm } from 'react-hook-form';
import addMinutes from 'date-fns/addMinutes';
import { Species } from 'shared/enums/Species';
import { IFormTemplate } from 'shared/interfaces/IFormTemplate';
import { FormQuestionType } from 'shared/enums/FormQuestionType';
import { GATrack } from 'shared/hooks/useGA';
import { postRequestSubmittedEvent } from 'shared/utils/requestSubmitted';
import { GA4Events } from 'shared/enums/GA4Events';
import { WidgetRequestTypeEventPrefix } from 'shared/enums/WidgetRequestTypeEventPrefix';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import format from 'date-fns/format';
import { Flex, Box } from '@televet/kibble-ui/build/chakra';
import { AvatarGroup } from '@televet/kibble-ui/build/components/AvatarGroup';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Link } from '@televet/kibble-ui/build/components/Link';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { getSpeciesTypeIcon } from 'shared/utils/getSpeciesTypeIcon';
import { useWidgetStore } from 'state/useWidgetStore';
interface IBookingConfirmationProps {
  setAppointmentDetails: (details: IAppointmentDetails) => void;
  appointmentDetails: IAppointmentDetails | undefined;
  integrations: ClinicPimsIntegrationSelectionFragment[];
}

const BookingConfirmation = ({ setAppointmentDetails, appointmentDetails }: IBookingConfirmationProps): JSX.Element => {
  const {
    clinicId,
    selectedRequest,
    parentUrl,
    clinicName,
    clinicPetParent,
    isPetPortal,
    goBackToMainMenu,
    goToStep,
    goToNextStep,
    authentication: { isNewClient },
  } = useWidgetStore((state) => state);
  const [confirmed, setConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleSubmit } = useForm<IAppointmentDetails>();

  const [createDirectBookingAppointment] = useCreateDirectBookingAppointmentMutation();

  const [createClinicWidgetRequest] = useCreateClinicWidgetRequestMutation();

  const startTime = appointmentDetails?.appointmentStartTime;
  const duration = appointmentDetails?.appointmentType?.defaultDurationInMinutes;
  const appointmentType = appointmentDetails?.appointmentType;

  const { data: hasConflictingAppointmentData } = useGetHasConflictingAppointmentQuery({
    variables: {
      data: {
        clinicPetId: appointmentDetails?.clinicPet?.id || '',
        startAt: startTime,
        appointmentTypeId: appointmentType?.id || '',
        clinicId,
      },
    },
    skip: !appointmentType?.id || !appointmentDetails?.clinicPet?.id,
  });

  const formattedAppointmentTimeframe = useMemo((): string => {
    if (!startTime || !duration) {
      return '';
    }

    const start = getFormattedTime(startTime);
    const end = getFormattedTime(addMinutes(startTime, duration));

    return `${start} - ${end}`;
  }, [startTime, duration]);

  const editDate = useCallback(() => {
    GATrack(GA4Events.DIRECT_BOOKING_SUMMARY_EDIT, {
      clinicId,
      editField: 'Date',
    });

    setAppointmentDetails({ ...appointmentDetails, doctorId: undefined });
    goToStep('Calendar');
  }, [appointmentDetails, clinicId, goToStep, setAppointmentDetails]);

  const editTime = useCallback(() => {
    GATrack(GA4Events.DIRECT_BOOKING_SUMMARY_EDIT, {
      clinicId,
      editField: 'Time',
    });

    goToStep('Times');
  }, [clinicId, goToStep]);

  const appointmentDescription = useMemo((): string => {
    let description = '[Otto Direct Booking]';

    if (isNewClient) {
      description = '[Otto Direct Booking - New Client]';
    }

    if (!appointmentDetails) return description;

    return description.concat(`\nReason for Visit: ${appointmentDetails.reasonForVisit}`);
  }, [appointmentDetails, isNewClient]);

  const handleConfirmAppointment = useCallback(async () => {
    setIsSubmitting(true);

    let appointmentId: string | undefined = undefined;

    try {
      const appointmentCreated = await createDirectBookingAppointment({
        variables: {
          data: {
            clinic: {
              connect: {
                id: clinicId,
              },
            },
            appointmentType: {
              connect: {
                id: appointmentDetails?.appointmentType?.id || '',
              },
            },
            clinicPet: {
              connect: {
                id: appointmentDetails?.clinicPet?.id || '',
              },
            },
            clinicPetParents: {
              connect: [
                {
                  id: clinicPetParent?.id || '',
                },
              ],
            },
            clinicEmployees: {
              connect: [
                {
                  id: appointmentDetails?.doctorId || '',
                },
              ],
            },
            workflows: { connect: [] },
            description: appointmentDescription,
            durationInMinutes: appointmentDetails?.appointmentType?.defaultDurationInMinutes || 30,
            startAt: appointmentDetails?.appointmentStartTime,
          },
        },
      });

      appointmentId = appointmentCreated?.data?.createDirectBookingAppointment?.id || undefined;

      GATrack(GA4Events.DIRECT_BOOKING_APPT_INTAKE_SUCCESS, {
        clinicId,
        appointmentType: appointmentDetails?.appointmentType?.name,
      });
    } catch (e) {
      GATrack(GA4Events.DIRECT_BOOKING_APPT_INTAKE_FAILURE, {
        clinicId,
        error: e,
      });

      console.error(e);
      Sentry.captureException(e);
    }

    // TODO: This is hard-coded for now. In the future we may use a dynamic form to allow question customization
    const requestFormSubmission: IFormTemplate = Object.assign({});
    requestFormSubmission.questions = [
      {
        index: 0,
        prompt: 'Pet Name',
        type: FormQuestionType.TextInput,
        isRequired: true,
        answer: appointmentDetails?.clinicPet?.name,
      },
      {
        index: 1,
        prompt: 'Appointment Type',
        type: FormQuestionType.TextInput,
        isRequired: true,
        answer:
          appointmentDetails?.appointmentType?.displayName || appointmentDetails?.appointmentType?.name || undefined,
      },
      {
        index: 2,
        prompt: 'Reason for Visit',
        type: FormQuestionType.MultiLineTextInput,
        isRequired: true,
        answer: appointmentDetails?.reasonForVisit,
      },
      {
        index: 3,
        prompt: 'Appointment Time',
        type: FormQuestionType.TextInput,
        isRequired: true,
        answer: appointmentDetails?.appointmentStartTime
          ? `${format(appointmentDetails.appointmentStartTime, 'M/d/yyyy p')} ${
              appointmentDetails?.usedTimezone ? ` (${appointmentDetails?.usedTimezone})` : ''
            }`
          : 'Not specified',
      },
      {
        index: 4,
        prompt: 'Preferred Provider',
        type: FormQuestionType.TextInput,
        isRequired: false,
        answer: appointmentDetails?.doctorName,
      },
    ];

    try {
      const { data } = await createClinicWidgetRequest({
        variables: {
          data: {
            url: parentUrl,
            clinic: { connect: { id: clinicId } },
            clinicWidgetRequestType: { connect: { id: selectedRequest?.id } },
            clinicPetParent: { connect: { id: clinicPetParent?.id } },
            requestFormSubmission,
            directBookingAppointment: { connect: { id: appointmentId } },
          },
        },
      });

      postRequestSubmittedEvent(parentUrl, {
        requestType: WidgetRequestType.DirectBooking,
        isNewClient,
        clinicName: clinicName || '',
        clinicId,
        channelId: data?.createClinicWidgetRequest?.channelId,
      });

      if (!isPetPortal) {
        goToNextStep();
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    } finally {
      setConfirmed(true);
      setIsSubmitting(false);
    }
  }, [
    appointmentDetails,
    createDirectBookingAppointment,
    clinicId,
    clinicPetParent?.id,
    appointmentDescription,
    createClinicWidgetRequest,
    parentUrl,
    selectedRequest?.id,
    isNewClient,
    clinicName,
    isPetPortal,
    goToNextStep,
  ]);

  const appointmentTypeName = useMemo(() => appointmentType?.displayName || appointmentType?.name, [
    appointmentType?.displayName,
    appointmentType?.name,
  ]);

  const goToMainMenu = useCallback(() => {
    GATrack(`${WidgetRequestTypeEventPrefix[WidgetRequestType.DirectBooking]}_${GA4Events.RETURN_MENU}`, { clinicId });
    goBackToMainMenu();
    setAppointmentDetails({});
  }, [clinicId, goBackToMainMenu, setAppointmentDetails]);

  return (
    <Flex bgColor="background.default" flex="1 1 auto" overflow="auto" alignSelf="stretch">
      <Box as="form" w="100%" onSubmit={handleSubmit(handleConfirmAppointment)}>
        <Flex align="center" mt={6} gap={3} flexDir="column">
          <AvatarGroup
            size="xl"
            showAddButton={false}
            avatars={[
              {
                name: `${clinicPetParent?.firstName} ${clinicPetParent?.lastName}`,
                bgColor: 'background.primary',
                border: 'none',
              },
              { iconName: getSpeciesTypeIcon(appointmentDetails?.clinicPet?.species || Species.Other), border: 'none' },
            ]}
          />

          <Heading size="sm" textAlign="center">
            {confirmed ? 'Your Appointment Has Been Booked!' : 'Confirm Your Appointment Details'}
          </Heading>

          <Text textAlign="center" size="sm" mx="50px">
            {confirmed ? "You've just booked " : "You're about to book "}

            <Text fontWeight="semibold" size="sm">
              {appointmentTypeName
                ? `${getIndefiniteArticle(appointmentTypeName)} ${appointmentTypeName} appointment`
                : 'an appointment'}
            </Text>

            {appointmentDetails?.doctorName && (
              <>
                {` with `}
                <Text fontWeight="semibold" size="sm">
                  {appointmentDetails.doctorName}
                </Text>
              </>
            )}

            {appointmentDetails?.clinicPet?.name && (
              <>
                {` for `}
                <Text fontWeight="semibold" size="sm">
                  {appointmentDetails.clinicPet.name}
                </Text>
              </>
            )}
            {` on:`}
          </Text>

          <Flex w="100%" flexDir="column" bgColor="background.subtle" p={4} gap={3}>
            <Flex justify="space-between" align="center">
              <Flex gap={2} align="center">
                <Icon name="calendarDate" />
                <Text size="sm">Date: </Text>
                <Text size="sm" fontWeight="bold">
                  {getFormattedDate(startTime)}
                </Text>
              </Flex>
              {!confirmed && (
                <Link variant="default" size="sm" iconProps={{ name: 'pen', position: 'end' }} onClick={editDate}>
                  Edit
                </Link>
              )}
            </Flex>

            <Flex justify="space-between" align="center">
              <Flex gap={2} align="center">
                <Icon name="clock" />
                <Text size="sm">Time: </Text>
                <Text size="sm" fontWeight="bold">
                  {formattedAppointmentTimeframe}{' '}
                  {appointmentDetails?.usedTimezone ? ` (${appointmentDetails?.usedTimezone})` : ''}
                </Text>
              </Flex>
              {!confirmed && (
                <Link variant="default" size="sm" iconProps={{ name: 'pen', position: 'end' }} onClick={editTime}>
                  Edit
                </Link>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex justify="center" p={4}>
          <Text textAlign="center">
            {!confirmed ? (
              <>
                {clinicPetParent?.email && (
                  <>
                    <Text size="sm" fontWeight="semibold" mr={1}>
                      Your Email Address:
                    </Text>
                    <Text size="sm">{clinicPetParent.email}</Text>
                  </>
                )}
              </>
            ) : (
              'You should recieve a confirmation email or text message shortly.'
            )}
          </Text>
        </Flex>

        {!!hasConflictingAppointmentData?.hasConflictingAppointment && (
          <Box px={4} mb={4}>
            <Alert
              title="We are unable to book your appointment"
              description="It looks like your pet is already booked for this day. Please contact the clinic to make changes to your appointment."
              status="warning"
              hideCloseButton={true}
            />
          </Box>
        )}

        <Flex p={4} borderTopWidth={1} justify="center">
          {confirmed ? (
            <Link size="sm" onClick={goToMainMenu}>
              Go Back to Main Menu
            </Link>
          ) : (
            <Button
              isFullWidth={true}
              type="submit"
              isLoading={isSubmitting}
              isDisabled={isSubmitting || !!hasConflictingAppointmentData?.hasConflictingAppointment}
              onClick={(): void =>
                GATrack(`${WidgetRequestTypeEventPrefix['DirectBooking']}_${GA4Events.COMPLETE}`, { clinicId })
              }
              data-testid="submit-button"
            >
              Confirm Appointment Details
            </Button>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export default BookingConfirmation;
